import { useState, useEffect } from 'react';

import { cn } from '~/utils/tw';

interface LazyImageProps extends React.ComponentProps<'img'> {}

export function LazyImage({ src, alt, className, ...props }: LazyImageProps) {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const image = new Image();
    image.src = src || '';
    image.onload = () => {
      setLoaded(true);
    };
    return () => image.remove();
  }, [src]);

  return (
    <img
      src={src}
      alt={alt}
      className={cn(
        'transition-opacity',
        loaded ? 'opacity-100' : 'opacity-0',
        className,
      )}
      {...props}
    />
  );
}
